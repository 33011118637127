import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PageQueryParams } from '../models/pageQueryParams';
import fetchClient from './axiosInstance';
import reportsApi from './reportsApi';

interface TrendsShareQueryParams extends PageQueryParams {
  months: string;
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}
export default class TrendsShareService {
  static async getTrendsShareByCompany(
    params: TrendsShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_trends/local_company/`, { params, ...customConfig });
  }

  static async getTrendsShareByBrand(
    params: TrendsShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_trends/brand/`, { params, ...customConfig });
  }

  static async getTrendsShareByBarcode(
    params: TrendsShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_trends/barcode/`, { params, ...customConfig });
  }

  static async getChartTrendsShareByCompany(
    params: TrendsShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_trends/local_company/graph/`, { params, ...customConfig });
  }

  static async getChartTrendsShareByBrand(
    params: TrendsShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_trends/brand/graph/`, { params, ...customConfig });
  }

  static async getChartTrendsShareByBarcode(
    params: TrendsShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_trends/barcode/graph/`, { params, ...customConfig });
  }

  static async exportByType(
    type: string,
    params: TrendsShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/share_trends/${type}/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }
}
