import { Checkbox, Form, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';

type Props = {
  isModal: boolean;
  setFieldValue: (name: string, value: any) => void;
  value: any;
};

const defaultStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
};

export const StoreFormItemsNew = ({ isModal, setFieldValue, value }: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName={'checked'} name={['sales_by_stores']} noStyle>
          <Popconfirm
            title="Эти данные конфиденциальны. Вы уверены что хотите дать доступ?"
            okText="OK"
            placement="topLeft"
            onCancel={() => {
              setIsChecked(false);
              setFieldValue('sales_by_stores', false);
            }}
            onConfirm={() => {
              setIsChecked(true);
              setFieldValue('sales_by_stores', true);
            }}
          >
            <Checkbox checked={isChecked} />
          </Popconfirm>
        </Form.Item>
        <label>&nbsp;&nbsp;View sales by stores</label>
      </Form.Item>

      {/* <Form.Item style={defaultStyles}>
          <Form.Item valuePropName="checked" name={['store_statistics']} noStyle>
            <Checkbox onChange={(val) => setIsChanged(true)} />
          </Form.Item>
          <label>&nbsp;&nbsp;View store statistics</label>
        </Form.Item> */}
    </>
  );
};
