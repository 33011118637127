import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PageQueryParams } from '../models/pageQueryParams';
import fetchClient from './axiosInstance';
import reportsApi from './reportsApi';

interface MarketShareQueryParams extends PageQueryParams {
  months: string;
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory_id?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}

export default class MarketShareService {
  static async getMarketShareByCompany(
    params: MarketShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/market_share/table/local_company/`, { params, ...customConfig });
  }

  static async getMarketShareByBrand(
    params: MarketShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/market_share/table/brand/`, { params, ...customConfig });
  }

  static async getMarketShareByBarcode(
    params: MarketShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/market_share/table/barcode/`, { params, ...customConfig });
  }

  static async getMarketShareGraphByCompany(
    params: MarketShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/market_share/graph/local_company/`, { params, ...customConfig });
  }

  static async getMarketShareGraphByBrand(
    params: MarketShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/market_share/graph/brand/`, { params, ...customConfig });
  }

  static async getMarketShareGraphByBarcode(
    params: MarketShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/market_share/graph/barcode/`, { params, ...customConfig });
  }

  static async exportByType(
    type: string,
    params: MarketShareQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/market_share/table/${type}/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }
}
