import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Button, Drawer, Dropdown, RadioChangeEvent } from 'antd';
import { FilterOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { useMediaQuery } from 'react-responsive';

import { MarketShareCategory } from './MarketShareCategory';
import { CustomFilter } from '../../components/filter/CustomFilter';
// import { Tag } from '../../components/filter-chips/filterChips';
import { ToggleMenuItems } from '../../components/toggleMenuItems/ToggleMenuitems';
import { RadioGroupMenu } from '../../components/RadioGroupMenu/RadioGroupMenu';
// import FilterService from '../../services/filter';
// import { downloadFile } from '../../utils/downloadFile';
import { dateRange, openNotification } from '../../utils/lastThreeMonths';
import MarketShareService from '../../services/marketShare';
import { isTobacco } from '../../utils/isTobacco';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import styles from './styles.module.scss';

export const MarketSharePage = () => {
  const { type } = useParams<{ type: string }>();
  const location = useLocation();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [openFilter, setOpenFilter] = useState(false);
  // const [tags, setTags] = useState<Tag[]>([]);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  // const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const rules = JSON.parse(localStorage.getItem('user'))?.role?.regions?.join(',');
  // const typeOfCompany = JSON.parse(localStorage.getItem('user'))?.company?.categories[0]?.category;
  const [page, setPage] = useState<string>('company');
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);

  const [openColumnMenu, setOpenColumnMenu] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState({
    // value_share: { title: 'Value share', value: false },
    value: { title: 'Value', value: false },
    // items_share: { title: 'Items share', value: true },
    items: { title: 'Items', value: true },
    // ...(typeOfCompany !== 'tobacco' && { volume_share: { title: 'Volume share', value: true } }),
    ...(!isTobacco() && { volume: { title: 'Volume', value: true } }),
    numeric_distribution: { title: 'Numeric distr.', value: false },
    weighted_distribution: { title: 'Weighted distr.', value: true },
  });

  const onChangePage = ({ target: { value } }: RadioChangeEvent) => {
    setPage(value);
    history.replace({ pathname: `/market-share/${value}`, search: history.location.search });
  };

  useEffect(() => {
    if (location.pathname.includes('company')) {
      setPage('company');
    } else if (location.pathname.includes('brand')) {
      setPage('brand');
    } else if (location.pathname.includes('barcode')) {
      setPage('barcode');
    }
    return () => {
      setPage('company');
    };
  }, [location.pathname]);

  const handleOpenColumMenuChange = (flag: boolean) => {
    setOpenColumnMenu(flag);
  };

  const handleToggleComunMenu = (key) => {
    setHiddenColumns((prevColumns) => ({
      ...prevColumns,
      [key]: { ...prevColumns[key], value: !prevColumns[key].value },
    }));
  };

  // const onChipClose = (tag: Tag) => {
  //   queryParams.delete(tag.filter);
  //   history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  // };

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const handleDownloadFile = () => {
    setExportLoading(true);
    MarketShareService.exportByType(type === 'company' ? 'local_company' : type, {
      months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
      city: params?.city || rules || '24',
      limit: +params?.limit || 10,
      ...params,
    })
      .then((res) => {
        setIsOpenDonwloads(true);
        openNotification('File added to downloads folder', 'success');
        // downloadFile(
        //   res.data,
        //   `market_share_report_${type}_${params?.months || defaultStartDate}` || 'market_share_report_'
        // );
      })
      .finally(() => setExportLoading(false));
  };

  // useEffect(() => {
  //   let arr: Tag[] = [];
  //   Object.keys(params).map((item) => {
  //     if (item !== 'page' && item !== 'limit') {
  //       switch (item) {
  //         case 'city':
  //           FilterService.getNewOptions(item, params[item]).then((res) => {
  //             setTags((prevState) => [
  //               ...prevState,
  //               { filter: item, name: params[item], label: res.data.results?.map((val: any) => val?.title)?.join(',') },
  //             ]);
  //           });
  //           break;
  //         case 'type_of_package':
  //         case 'brand':
  //         case 'local_company':
  //         case 'subcategory':
  //           FilterService.getNewOptions(item, params[item]).then((res) => {
  //             setTags((prevState) => [
  //               ...prevState,
  //               { filter: item, name: params[item], label: res.data.results?.map((val: any) => val[item])?.join(',') },
  //             ]);
  //           });
  //           break;
  //         default:
  //           arr.push({
  //             filter: item,
  //             name: params[item],
  //           });
  //       }
  //     }
  //     return arr;
  //   });
  //   setTags(arr);
  //   return () => {
  //     setTags([]);
  //   };
  // }, [location.search]);

  return (
    <>
      <div className={styles.company_layout_content}>
        <div className={styles.top_bar}>
          <div className={styles.wrapper}>
            {!isTabletOrMobile && (
              <div className={styles.title}>
                <RadioGroupMenu onChange={onChangePage} value={page} />
              </div>
            )}
            {isTabletOrMobile && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <div>
                  <Drawer
                    title=""
                    placement="left"
                    onClose={hide}
                    open={openFilter}
                    width={isTabletOrMobile ? '320' : undefined}
                  >
                    <CustomFilter type={type} onFilterChange={onFilterChange} onCancel={hide} />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  />
                </div>
                {/* <div style={{ marginLeft: '15px' }}>
              <FilterChips tags={tags} onClose={onChipClose} />
            </div> */}
              </div>
            )}
            {!isTabletOrMobile && (
              <>
                <div style={{ marginLeft: '15px' }}>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <CustomFilter type={type} onFilterChange={onFilterChange} onCancel={hide} />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  >
                    &nbsp;&nbsp;Filter
                  </Button>
                </div>
                {/* <div style={{ marginLeft: '15px' }}>
                  <FilterChips tags={tags} onClose={onChipClose} />
                </div> */}
              </>
            )}
          </div>
          {!isTabletOrMobile && (
            <Dropdown
              menu={ToggleMenuItems(hiddenColumns, handleToggleComunMenu)}
              placement="bottom"
              onOpenChange={handleOpenColumMenuChange}
              open={openColumnMenu}
            >
              <Button>Table settings</Button>
            </Dropdown>
          )}
          &nbsp;&nbsp;&nbsp;
          <Button type="default" className={styles.export_btn} onClick={handleDownloadFile} loading={exportLoading}>
            <VerticalAlignBottomOutlined />
            Export
          </Button>
        </div>

        <MarketShareCategory pageName={type} hiddenColumns={hiddenColumns} isMobile={isTabletOrMobile} />
      </div>
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </>
  );
};
