import { ComponentType, FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Profile } from '../models/authModel';

type Props = {
  component: ComponentType<{ profile: Profile; logout?: () => void }>;
  loading?: boolean;
  profile: Profile;
  logout?: () => void;
  [key: string]: any;
};

export const PrivateRoute: FC<Props> = ({ component: Component, loading, profile, logout, ...rest }) => {
  return !loading ? (
    <Route
      {...rest}
      render={
        (props) =>
          profile !== null ? ( // add auth check here
            <Component profile={profile} logout={logout} {...props} />
          ) : null

        // <Redirect
        //   to={{
        //     pathname: '/login',
        //   }}
        // />
      }
    />
  ) : null;
};
